



















import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ActionMethod } from 'vuex';

@Component({
  name: 'Assessments'
})
export default class Assessments extends Vue {
  @Action fetchLeanAssessmentUrl!: ActionMethod;

  private assessmentUrl: string | null = null;

  private isLoading = true;

  onLoad(): void {
    this.isLoading = false;
    this.$forceUpdate();
  }

  @Watch('$route')
  onRouteChange(): void {
    // Refresh the iframe
    const iframe = this.$refs.iframe as HTMLIFrameElement;
    // eslint-disable-next-line no-self-assign
    iframe.src = iframe.src;
  }

  async created(): Promise<void> {
    this.assessmentUrl = await this.fetchLeanAssessmentUrl();
  }
}
